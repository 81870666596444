import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { MapRef } from 'react-map-gl';
import Map, { Marker } from 'react-map-gl';
import { useInterval } from 'react-use';

import { GetOnboardedCounts } from '@endaoment-frontend/api';
import { config } from '@endaoment-frontend/config';
import { CONTINENT, COUNTRY_GEODATA } from '@endaoment-frontend/constants';
import type { ContinentEntry, ContinentName, CountryCode, CountryEntry } from '@endaoment-frontend/types';
import { FlagIcon, GlobeAltIcon, GlobeIcon, MapIcon, PauseIcon, PlayIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';
import { formatNumber } from '@endaoment-frontend/utils';

import styles from './DeployMapSection.module.scss';

import 'mapbox-gl/dist/mapbox-gl.css';

const MIN_ZOOM = 1.5;
const MAX_ZOOM = 4;
const MAX_VISIBLE_ZOOM = 6.5;
const COUNTRY_ZOOM_THRESHOLD = 3.0;

type CountryClickEventType = (country: CountryEntry) => void;
type ContinentClickEventType = (continent: ContinentEntry) => void;
type LocationTagType =
  | { code: ContinentName; onClick?: ContinentClickEventType; count: number; type: 'continent' }
  | { code: CountryCode; onClick?: CountryClickEventType; count: number; type: 'country' };

const calculateVisibleRange = (zoomLevel: number, centerLat: number, centerLon: number) => {
  // Given a spherical projection of the Earth, calculate the visible range of latitudes and longitudes.
  // This is used to determine whether a country is visible on the map.
  const R = 10000;
  const C = 2 * Math.PI * R;

  // The number of degrees of latitude visible at the current zoom level.
  const visibleLat = 550 * (360 / C) * Math.pow(2, MAX_VISIBLE_ZOOM - zoomLevel);
  // The number of degrees of longitude visible at the current zoom level.
  const visibleLon = 1250 * (360 / C) * Math.pow(2, MAX_VISIBLE_ZOOM - zoomLevel);

  return {
    minLat: centerLat - visibleLat / 2,
    maxLat: centerLat + visibleLat / 2,
    minLon: centerLon - visibleLon / 2,
    maxLon: centerLon + visibleLon / 2,
  };
};

// This function checks whether a country is visible.
const isVisible = (
  zoomLevel: number,
  centerLat: number,
  centerLon: number,
  lat: number,
  lon: number,
  count: number,
) => {
  const range = calculateVisibleRange(zoomLevel, centerLat, centerLon);

  // The country is visible if its coordinates are within the visible range
  // and the current zoom level is higher than the threshold.
  return lat >= range.minLat && lat <= range.maxLat && lon >= range.minLon && lon <= range.maxLon;
};

const LocationTag = ({ code, count, type, onClick }: LocationTagType) => {
  const handleClick = useCallback(() => {
    if (type === 'country') {
      onClick?.(COUNTRY_GEODATA[code]);
      return;
    }

    onClick?.(CONTINENT[code]);
  }, [code, type, onClick]);

  return (
    <Pill size='tiny' onClick={handleClick} className={styles['location']}>
      {type === 'country' ? (
        <>
          <FlagIcon countryCode={code} className={styles['pill-icon']} />
          <div>{COUNTRY_GEODATA[code].name}</div>
        </>
      ) : (
        <>
          <GlobeIcon className={styles['pill-icon']} color='currentColor' />
          {code}
        </>
      )}
      <b>{formatNumber(count, { compact: true, digits: 3, stripZeros: true })}</b>
    </Pill>
  );
};

export const DeployMapSection = ({
  onClickCountry,
  onClickContinent,
}: {
  onClickCountry?: (location: CountryEntry) => void;
  onClickContinent?: (location: ContinentEntry) => void;
}) => {
  const mapRef = useRef<MapRef>(null);

  const [globeView, setGlobeView] = useState(true);
  const [mapTouched, setMapTouched] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: 37.0,
    latitude: 15.0,
    zoom: MIN_ZOOM,
  });

  const { data: onboardedCounts } = GetOnboardedCounts.useQuery([]);

  // If this is set too low it will cause the page to lag
  useInterval(
    () => {
      if (mapRef.current) {
        mapRef.current.easeTo({
          center: [viewState.longitude + 5, viewState.latitude],
          duration: 1005,
          easing: t => t,
        });
      }
    },
    mapTouched ? null : 1000,
  );

  const isAboveCountryThreshold = viewState.zoom < COUNTRY_ZOOM_THRESHOLD;
  const displayedCountries = useMemo(
    () =>
      onboardedCounts
        ? (Object.keys(onboardedCounts.countries) as Array<CountryCode>).filter(countryCode =>
            isVisible(
              viewState.zoom,
              viewState.latitude,
              viewState.longitude,
              COUNTRY_GEODATA[countryCode].location.latitude,
              COUNTRY_GEODATA[countryCode].location.longitude,
              onboardedCounts.countries[countryCode],
            ),
          )
        : [],
    [onboardedCounts, viewState],
  );

  const markers = useMemo(() => {
    if (!onboardedCounts) return <></>;

    const zoomToContinent = (continent: ContinentEntry) => {
      setMapTouched(true);
      if (mapRef.current) {
        mapRef.current.easeTo({
          center: [continent.location.longitude, continent.location.latitude],
          zoom: COUNTRY_ZOOM_THRESHOLD,
        });
      }
    };

    if (isAboveCountryThreshold) {
      return (Object.keys(onboardedCounts.continents) as Array<ContinentName>).map(continentCode => {
        const continent = CONTINENT[continentCode];
        return (
          <Marker key={continentCode} latitude={continent.location.latitude} longitude={continent.location.longitude}>
            <LocationTag
              code={continentCode}
              count={continentCode === 'North America' ? 1800000 : onboardedCounts.continents[continentCode]}
              onClick={continent => {
                zoomToContinent(continent);
                onClickContinent?.(continent);
              }}
              type='continent'
            />
          </Marker>
        );
      });
    }

    return displayedCountries.map(countryCode => {
      const country = COUNTRY_GEODATA[countryCode];
      return (
        <Marker key={countryCode} latitude={country.location.latitude} longitude={country.location.longitude}>
          <LocationTag
            code={countryCode}
            count={country.code === 'USA' ? 1800000 : onboardedCounts.countries[countryCode]}
            onClick={onClickCountry}
            type='country'
          />
        </Marker>
      );
    });
  }, [onboardedCounts, onClickContinent, onClickCountry, isAboveCountryThreshold, displayedCountries]);

  return (
    <div className={styles.container}>
      <Map
        mapStyle='mapbox://styles/endaoment/clhjc9qxd001y01qnchng5o0b'
        {...viewState}
        onMove={e => setViewState(e.viewState)}
        projection={globeView ? { name: 'globe' } : { name: 'mercator' }}
        style={{
          width: '100%',
          height: 'var(--map-height)',
          background: 'var(--map-bg)',
          borderRadius: 'var(--border-radius)',
          overflow: 'hidden',
        }}
        mapboxAccessToken={config.mapboxApiKey}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        onMouseDown={() => setMapTouched(true)}
        onTouchStart={() => setMapTouched(true)}
        ref={mapRef}
        reuseMaps>
        {markers}
      </Map>
      <div className={styles.title__container}>
        <div className={styles.title__outer}>
          <Pill className={styles.title}>
            <GlobeIcon className={styles.globe} />
            <span>
              <b>1.8m</b>
              Eligible Nonprofits
            </span>
          </Pill>
          <div className={styles['controls']}>
            <Button
              onClick={() => setMapTouched(!mapTouched)}
              size='small'
              variation='purple'
              className={styles['control-button']}
              float={false}>
              {mapTouched ? (
                <>
                  <PlayIcon color='currentColor' />
                  Play
                </>
              ) : (
                <>
                  <PauseIcon color='currentColor' />
                  Pause
                </>
              )}
            </Button>
            <Button
              onClick={() => setGlobeView(!globeView)}
              filled
              size='small'
              variation='purple'
              className={clsx(
                styles['control-button'],
                globeView ? styles['control-button--map'] : styles['control-button--globe'],
              )}
              float={false}>
              {globeView ? (
                <>
                  <MapIcon color='currentColor' />
                  Map
                </>
              ) : (
                <>
                  <GlobeAltIcon color='currentColor' />
                  Globe
                </>
              )}
              &nbsp;View
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployMapSection;
